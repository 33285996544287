const hasOwnPolyfill = () => {
    if (!Object.hasOwn) {
        Object.hasOwn = (obj, prop) => {
            return Object.prototype.hasOwnProperty.call(obj, prop);
        };
    }
};

const getRandomValuesPolyfill = () => {
    if (!window.crypto) {
        window.crypto = {} as Crypto;
    }

    if (!window.crypto.getRandomValues) {
        window.crypto.getRandomValues = <T extends ArrayBufferView | null>(array: T): T => {
            if (!array) {
                throw new TypeError("Input array cannot be null or undefined");
            }

            if (
                !(
                    array instanceof Int8Array ||
                    array instanceof Uint8Array ||
                    array instanceof Int16Array ||
                    array instanceof Uint16Array ||
                    array instanceof Int32Array ||
                    array instanceof Uint32Array
                )
            ) {
                throw new TypeError("Expected a typed array instance");
            }

            for (let i = 0; i < array.length; i += 1) {
                // eslint-disable-next-line no-param-reassign
                array[i] = Math.floor(Math.random() * 256);
            }

            return array;
        };
    }
};

export const registerAllPolyfills = () => {
    hasOwnPolyfill();
    getRandomValuesPolyfill();
};
